import { global } from "../constants";

export const data = {
  banner: {
    background: global.banner + "1.jpg",
    title: "FAQ",
    crumb: "FAQ",
  },
  faq: {
    title: "FAQ's",
    subtitle: "Ko'p So'raladigan Savollar?",
    description:
      "Agar sizda boshqa savollar mavjud bo'lsa yoki savolingizga javob olmagan bo'lsangiz bizga raqamingizni qoldiring va biz sizga aloqaga chiqib barcha savollaringizga javob beramiz!",
    faqs: [
      {
        que: "Cargo Narxi Qancha?",
        ans: `✈️ DTD AVIA CARGO Tarifi:
- 0 kg dan 50 kg gacha: 9.49 $ (har bir kg)
(Karobkasiz, DUR keshbeklari bilan)

Maxsus tariflarimiz:
- 50.1 kg dan 70 kg gacha: 9 $ (har bir kg)
- 70.1 kg dan 90 kg gacha: 8.5 $ (har bir kg)
- 90.1 kg dan ko'pi: 8 $ (har bir kg)

Kelish muddati: 7 - 10 kun
-------------------------------------
🚛 DTD AVTO CARGO Tarifi:
- 50 kg gacha: 7 $
- 50.1 kg dan 100 kg gacha: 6.5 $
- 100.1 kg dan tepasi: 6 $

Kelish muddati: 14 - 25 kun
-----------------------------------
📦 Elektronika, Brend, Parfumeriya va Lyuks Kopiya Maxsulotlar Tarifi:
- Parfyumeriya (krem, tush, shampun, pomada va hokazo): 8 $ (kg)
- Elektronikalar: 8 $ (kg)
- Brend kiyimlar: 8 $ (kg)

Kelish muddati: 14 - 25 kun
-----------------------------------
Izoh: Barcha tariflar karobkasiz yetkazib berishga mo‘ljallangan.

Yetkazish: Viloyat filiallarigacha bepul yetkazib beriladi.
       
Toshkent shahri bo'ylab yetkazib berish: 5-kilodan oshiq jo'natmalarni uyingizgacha tekinga yetkazamiz.

📌 Bundan tashqari, individual xizmat va narxlarimiz bor. Qo'shimcha ma'lumot uchun biz bilan bog'laning!
`,
      },
      {
        que: "Nechchi Kunda Keladi?",
        ans: `✈️ DTD AVIA CARGO:

Kelish muddati: 7 - 10 kun
-------------------------------------
🚛 DTD AVTO CARGO:

Kelish muddati: 14 - 25 kun
-----------------------------------
📦 Elektronika, Brend, Parfumeriya va Lyuks Kopiya Maxsulotlar:

Kelish muddati: 14 - 25 kun`,
      },
      {
        que: "Narsalar Yo'qolmaydimi?",
        ans: "Bizning zamonaviy kuzatuv tizimlarimiz va ehtiyotkorlik choralari bilan sizning yuklaringiz xavfsiz va o'z vaqtida yetkazilishini ta'minlaymiz. Har bir yuk biz uchun qimmatli va biz uni sizgacha yetkazishda maksimal darajada ehtiyot bo'lamiz. Mahsulot yo'qolgan taqdirda ham bizni omborga yetib kelgan holatda barchasi biz tarafdan qoplanadi!",
      },
      {
        que: "Mahsulotimni Kuzatib Borish Imkoni Bormi?",
        ans: "Ha, bizning raqamli kuzatuv xizmatimiz orqali sizning mahsulotingizni real vaqtda kuzatib borishingiz mumkin. Shunchaki bizning veb-saytimizdagi 'Trek raqam' bo'limiga o'ting va yukingizning holatini tekshiring.",
      },
      {
        que: "Qanday Mahsulotlarni Olib Kelish Mumkin emas?",
        ans: `❌ Olib kelish mumkin bo'lmagan mahsulotlar:

-Telefon
- Kompyuter
- Planshet va iPad
- Televizor
- O'simliklar
- Dorilar
- Dron
- Sovuq qurollar
- Intim mahsulotlar
- Yonuvchan moddalar (atirlar)
- Batareykasi asosiy qismini egallaydigan mahsulotlar (Powerbank)
- Tirik hayvonlar`,
      },
      {
        que: "DUR nima?",
        ans: `💎 DUR – bu Door to Door kompaniyasining keshbek tizimi. Har bir kilogramm yuk uchun 1 DUR ball olasiz. 1 DUR hozirda 50 gramm yuk narxiga teng. DURlarni to'plab, keyingi jo'natmalaringiz uchun ishlatishingiz mumkin.

👥 Do'stingizni taklif qiling: Do'stingizni taklif qilsangiz, qo'shimcha DUR ballariga ega bo'lasiz.

🎉 Aksiyalar va bayramlar: Aksiyalar va bayramlarda qo'shimcha DUR ballarini qo'lga kiriting.

🎁 Qo'shiling va 5 DUR oling!`,
      },
    ],
  },
};
