import { useState } from "react";
import { data } from "../../../globals/data/about-us";
import { NavLink } from "react-router-dom";
import { SectionFaq } from "../../sections/home/index3/section-faq";
import { data as faqData } from "../../../globals/data/faq";
import { SectionAddress } from "../../sections/home/index3/section-address";
import SectionTestimonials2 from "../../sections/home/index2/section-testimonials2";

export default function TermsPage() {
  const _data = data.whatwedo;
  const dataBanner = data.banner;
  const [isChecked, setIsChecked] = useState(false);
  return (
    <>
      <div
        className="wt-bnr-inr overlay-wraper bg-center"
        style={{ backgroundImage: `url(${dataBanner.background})` }}
      >
        <div className="overlay-main opacity-08" />
        <div className="container">
          <div className="wt-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t120 p-b90 site-bg-gray tw-what-wedo-area">
        <div className="container">
          <div className="tw-what-wedo-section">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="tw-service-icon-box-wrap">
                  <div class="privacy-policy">
                    <h2>Maxfiylik siyosati</h2>

                    <p>
                      Ushbu maxfiylik siyosati d2d.uz veb-sayti (keyingi
                      o‘rinlarda "Sayt") tomonidan foydalanuvchilar haqidagi
                      shaxsiy ma’lumotlarni yig‘ish, saqlash va ulardan
                      foydalanish tartibini belgilaydi. Foydalanuvchi ushbu
                      Saytdan foydalanish orqali mazkur siyosat shartlariga
                      rozilik bildiradi.
                    </p>

                    <h3>1. Yig‘iladigan ma’lumotlar</h3>
                    <p>Biz quyidagi shaxsiy ma’lumotlarni yig‘amiz:</p>
                    <ul>
                      <li>Ism</li>
                      <li>Telefon raqami</li>
                    </ul>

                    <h3>2. Ma’lumotlardan foydalanish maqsadi</h3>
                    <p>
                      Yig‘ilgan ma’lumotlar quyidagi maqsadlarda ishlatiladi:
                    </p>
                    <ul>
                      <li>
                        Foydalanuvchilarga xizmat ko‘rsatish va ularga murojaat
                        qilish
                      </li>
                      <li>
                        Reklama va marketing maqsadlarida xabarlar yuborish
                      </li>
                    </ul>

                    <h3>3. Shaxsiy ma’lumotlarning saqlanish muddati</h3>
                    <p>
                      Foydalanuvchilar shaxsiy ma’lumotlari kompaniyaning
                      faoliyati davomida yoki ushbu ma’lumotlar ishlov berish
                      maqsadi tugagunga qadar saqlanadi.
                    </p>

                    <h3>4. Ma’lumotlarni uchinchi tomonlarga berish</h3>
                    <p>
                      Biz foydalanuvchilarning shaxsiy ma’lumotlarini uchinchi
                      tomonlarga bermaymiz, faqat quyidagi holatlar bundan
                      mustasno:
                    </p>
                    <ul>
                      <li>Foydalanuvchi roziligi bilan</li>
                      <li>Qonunchilik talablari bo‘yicha</li>
                      <li>
                        Xizmatlarni taqdim etish uchun texnik sheriklar bilan
                        (masalan, server xizmatlari)
                      </li>
                    </ul>

                    <h3>5. Foydalanuvchining huquqlari</h3>
                    <p>Foydalanuvchi quyidagi huquqlarga ega:</p>
                    <ul>
                      <li>
                        O‘z shaxsiy ma’lumotlarini ko‘rish, o‘zgartirish yoki
                        o‘chirishni talab qilish
                      </li>
                      <li>
                        Shaxsiy ma’lumotlar ishlov berilishiga qarshi chiqish
                      </li>
                      <li>
                        Biz bilan bog‘lanib ma’lumotlarning ishlov berilishi
                        haqida batafsil ma’lumot olish
                      </li>
                    </ul>

                    <h3>6. Maxfiylik siyosatidagi o‘zgarishlar</h3>
                    <p>
                      Ushbu siyosatga o‘zgartirishlar kiritilishi mumkin.
                      O‘zgarishlar Saytda e’lon qilingan paytdan boshlab kuchga
                      kiradi. Foydalanuvchilarga yangi shartlar haqida oldindan
                      xabar beriladi.
                    </p>

                    <h3>7. Biz bilan bog‘lanish</h3>
                    <p>
                      Agar sizda ushbu siyosat yuzasidan savol yoki takliflar
                      bo‘lsa, biz bilan bog‘lanishingiz mumkin:
                    </p>
                    <p>
                      Email: <a href="mailto:info@d2d.uz">info@d2d.uz</a>
                    </p>
                    <p>Telefon: +998 XX XXX XX XX</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
